// myInteractAPI.js

// Define the API endpoint URL, default to prod now
var apiUrl = 'https://api.interact.technology/myinteract';
var midUrl = 'https://my.interact.technology/interact/#/login';
var xapiKey = 'AIzaSyDXWAi3N8tlqvrVHj1QPqDxh2rbpaCR9Hs';

// tweak the vars when the environment is in TEST
// const isTestEnvironment = window.location.hostname === 'test-regos.interact.technology' || 'localhost';
// if (isTestEnvironment) {
//     apiUrl = 'https://test-api.interact.technology/myinteract';
//     midUrl = 'https://test-my.interact.technology/interact/#/login';
//     xapiKey = 'AIzaSyCnjsQ2yr0ztEtTD7ix-uGBd8JxryCG3gM';
// }
// midUrl = 'http://localhost:9007/#login'

const encryptUrl = apiUrl + "/enc";
const contentType = 'application/x-www-form-urlencoded';

// Define the request headers (if needed)
const warmup_headers = {
    'x-api-key': xapiKey
};

// Define the request options
const warmup_options = {
    method: 'GET',
    headers: warmup_headers,
};

// Function to call the API to warm up the cloud function
function warmup() {
    fetch(apiUrl, warmup_options)
        .then(response => {
            // Handle the response
            if (!response.ok) {
                throw new Error('API request failed'); // Handle error response
            }
        })
        .then(data => {
            // Handle the data from the response
            //console.log(data);
        })
        .catch(error => {
            // Handle any errors that occurred during the API call
            console.error(error);
        });
}

// Function to call the API to enc the password and then direct to MID via 302
// added additional parameter companyId on 25/10/2023
function encryptAndRedirectToMID(email, password, companyId) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    const enc_headers = {
        'x-api-key': xapiKey,
        'Content-Type': contentType
    };

    const enc_options = {
        method: 'POST',
        headers: enc_headers,
        body: urlencoded,
        redirect: 'follow'
    };

    //console.log(encryptUrl);
    fetch(encryptUrl, enc_options)
        .then(response => {
            // Handle the response
            if (!response.ok) {
                throw new Error('API request failed'); // Handle error response
            }
            return response.json(); // Parse response as JSON
        })
        .then(data => {
            // Handle the data from the response
            //console.log(data);

            const queryParams = {
                email: data.email,
                enc_pass: data.enc_pass,
                iv: data.iv
            };

            // Create an array to hold the encoded query parameters
            var encodedParams = [];

            // Loop through the query parameters object and encode each key-value pair
            for (var key in queryParams) {
                if (queryParams.hasOwnProperty(key)) {
                    var encodedKey = encodeURIComponent(key);
                    var encodedValue = encodeURIComponent(queryParams[key]);
                    encodedParams.push(encodedKey + '=' + encodedValue);
                }
            }

            if (companyId) encodedParams.push("companyId" + '=' + companyId)

            console.info(midUrl + '?' + encodedParams.join('&'));
            window.location.replace(midUrl + '?' + encodedParams.join('&'));
        })
        .catch(error => {
            // Handle any errors that occurred during the API call
            console.error(error);
            //we redirect to MID anyway regardless the error
            window.location.replace(midUrl);
        });
}

// // Export the function to make it available for use in other scripts
// export {warmup};
// export {encryptAndRedirectToMID};
